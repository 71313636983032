<template>
  <div>
    <BasicEditor :html="text"></BasicEditor>
<!--    <wang-editor-self></wang-editor-self>-->
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      text: ""
    }
  },
  mounted() {
    let that = this
    setTimeout(() => {
      that.text = "<p>111sssssssssssss1</p>"
    }, 5000)
  }
}
</script>

<style scoped>

</style>